import React, { useState } from 'react';
import SurveyCard from '../../components/SurveyCard/SurveyCard';
import { TextField, MenuItem, Select, FormControl, InputLabel, Checkbox, ListItemText, Grid } from '@mui/material';

const categories = ['Games', 'Apps', 'Quizzes', 'Sign-ups'];
const platforms = ['PC', 'Console', 'Mobile'];

const Survey = () => {
  const [search, setSearch] = useState('');
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const surveys = [
    { id: 1, title: 'Survey 1', description: 'Description of Survey 1', image: 'survey1.jpg', value: 5, platform: 'Mobile', category: 'Quizzes' },
    { id: 2, title: 'Survey 2', description: 'Description of Survey 2', image: 'survey2.jpg', value: 15, platform: 'PC', category: 'Sign-ups' },
  ];

  const filteredSurveys = surveys.filter(survey =>
    survey.title.toLowerCase().includes(search.toLowerCase()) &&
    (selectedPlatforms.length === 0 || selectedPlatforms.includes(survey.platform)) &&
    (selectedCategories.length === 0 || selectedCategories.includes(survey.category))
  );

  const handlePlatformChange = (event) => {
    setSelectedPlatforms(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategories(event.target.value);
  };

  return (
    <div style={{ padding: '20px', background: 'linear-gradient(135deg, #1a202c, #2d3748)', minHeight: '90vh' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <TextField
          label="Search"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ flex: 1, marginRight: '20px', color: '#fff', backgroundColor: '#333', borderRadius: '4px' }}
          InputLabelProps={{ style: { color: '#fff' } }}
          InputProps={{ style: { color: '#fff', padding: '10px' } }}
        />
        <FormControl variant="outlined" style={{ minWidth: 200, marginRight: '20px' }}>
          <InputLabel style={{ color: '#fff', paddingLeft: '10px' }}>Platform</InputLabel>
          <Select
            multiple
            value={selectedPlatforms}
            onChange={handlePlatformChange}
            renderValue={(selected) => selected.join(', ')}
            style={{ color: '#fff', backgroundColor: '#333', borderRadius: '4px', padding: '10px' }}
          >
            {platforms.map((platform) => (
              <MenuItem key={platform} value={platform}>
                <Checkbox checked={selectedPlatforms.indexOf(platform) > -1} />
                <ListItemText primary={platform} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel style={{ color: '#fff', paddingLeft: '10px' }}>Category</InputLabel>
          <Select
            multiple
            value={selectedCategories}
            onChange={handleCategoryChange}
            renderValue={(selected) => selected.join(', ')}
            style={{ color: '#fff', backgroundColor: '#333', borderRadius: '4px', padding: '10px' }}
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                <Checkbox checked={selectedCategories.indexOf(category) > -1} />
                <ListItemText primary={category} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Grid container spacing={3}>
        {filteredSurveys.map(survey => (
          <Grid item xs={12} sm={6} md={8} lg={6} key={survey.id}>
            <SurveyCard title={survey.title} description={survey.description} img={survey.image} amount={`$${survey.value}`} id={survey.id} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Survey;
