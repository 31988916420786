import React, { useState } from 'react';
import { Container, Box, Typography, TextField, Button } from '@mui/material';

const Settings = () => {
  const [email, setEmail] = useState('jascdjasgdjasgj@gmail.com');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isEmailVerified] = useState(false);

  const handleEmailChange = () => {
    // Handle email change logic
  };

  const handlePasswordChange = () => {
    // Handle password change logic
  };

  const handleConfirmEmail = () => {
    // Handle email confirmation logic
  };

  const handleDeleteAccount = () => {
    // Handle delete account logic
  };

  return (
    <Container maxWidth="md" sx={{ mt: 8, backgroundColor: '#1a202c', padding: 4, borderRadius: 2, textAlign: 'center', color: '#fff' }}>
      <Typography variant="h4" sx={{ mb: 4 }}>Settings</Typography>
      {!isEmailVerified && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ color: '#ffcccc' }}>Email not verified</Typography>
          <Button variant="contained" color="secondary" onClick={handleConfirmEmail}>
            Resend Email Confirmation
          </Button>
        </Box>
      )}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6">Change Email Address</Typography>
        <TextField
          type="email"
          label="New Email Address"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2, input: { color: '#fff' }, label: { color: '#fff' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#fff' }, '&:hover fieldset': { borderColor: '#fff' } } }}
        />
        <TextField
          type="password"
          label="Current Password"
          variant="outlined"
          fullWidth
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          sx={{ mb: 2, input: { color: '#fff' }, label: { color: '#fff' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#fff' }, '&:hover fieldset': { borderColor: '#fff' } } }}
        />
        <Button variant="contained" color="primary" onClick={handleEmailChange}>
          Change Email
        </Button>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6">Change Password</Typography>
        <TextField
          type="password"
          label="Current Password"
          variant="outlined"
          fullWidth
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          sx={{ mb: 2, input: { color: '#fff' }, label: { color: '#fff' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#fff' }, '&:hover fieldset': { borderColor: '#fff' } } }}
        />
        <TextField
          type="password"
          label="New Password"
          variant="outlined"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          sx={{ mb: 2, input: { color: '#fff' }, label: { color: '#fff' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#fff' }, '&:hover fieldset': { borderColor: '#fff' } } }}
        />
        <TextField
          type="password"
          label="Confirm New Password"
          variant="outlined"
          fullWidth
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          sx={{ mb: 2, input: { color: '#fff' }, label: { color: '#fff' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#fff' }, '&:hover fieldset': { borderColor: '#fff' } } }}
        />
        <Button variant="contained" color="primary" onClick={handlePasswordChange}>
          Change Password
        </Button>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ color: '#ffcccc' }}>Delete Account</Typography>
        <Button variant="contained" color="error" onClick={handleDeleteAccount}>
          Delete Account
        </Button>
      </Box>
    </Container>
  );
};

export default Settings;
