import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const SurveyCard = ({ title, description, img, amount, id }) => {
  return (
    <Box
      component={Link}
      to={`/survey/${id}`}
      sx={{
        textDecoration: 'none',
        '&:hover .MuiCard-root': {
          transform: 'scale(1.05)',
        }
      }}
    >
      <Card
        sx={{
          backgroundColor: '#444',
          color: '#fff',
          borderRadius: '10px',
          overflow: 'hidden',
          transition: 'transform 0.2s',
        }}
      >
        <Box
          sx={{
            height: '150px',
            overflow: 'hidden'
          }}
        >
          <img
            src={img}
            alt={title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </Box>
        <CardContent
          sx={{
            padding: '10px',
            textAlign: 'center'
          }}
        >
          <Typography variant="h6">{title}</Typography>
          {description && <Typography variant="body2">{description}</Typography>}
          {amount && (
            <Typography
              variant="body2"
              sx={{
                marginTop: '10px',
                fontWeight: 'bold'
              }}
            >
              {amount}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default SurveyCard;
