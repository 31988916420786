import React, { useState } from 'react';
import { Button, Grid, Typography, Modal, Box } from '@mui/material';
import SelectionCard from '../../components/SelectionCard/SelectionCard';
import styles from './Withdrawals.module.css';

const Withdrawals = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [withdrawMethod, setWithdrawMethod] = useState('');

  const balance = 500; // Example balance
  const pendingBalance = 100; // Example pending balance

  const handleOpenModal = (method) => {
    setWithdrawMethod(method);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedOption('');
  };

  const handleSelectOption = (amount) => {
    setSelectedOption(amount);
  };

  const handleSubmit = () => {
    // Handle the submission logic here
    handleCloseModal();
  };

  const cashOptions = ['Paypal', 'Bank Transfer'];
  const giftCardOptions = ['Amazon', 'Visa', 'Google Play', 'Apple'];

  const amounts = {
    Paypal: ['$10', '$25', '$50', '$100'],
    'Bank Transfer': ['$20', '$50', '$100', '$200'],
    Amazon: ['$15', '$30', '$60', '$100'],
    Visa: ['$10', '$20', '$50', '$100'],
    'Google Play': ['$10', '$25', '$50'],
    Apple: ['$10', '$25', '$50', '$100'],
  };

  const transactionHistory = [
    { date: '2023-07-01', method: 'Paypal', amount: '-$50', status: 'Completed', type: 'withdrawal' },
    { date: '2023-07-02', method: 'Amazon', amount: '-$30', status: 'Pending', type: 'withdrawal' },
    { date: '2023-07-03', method: 'Bank Transfer', amount: '-$100', status: 'Completed', type: 'withdrawal' },
    { date: '2023-07-04', method: 'Visa', amount: '-$20', status: 'Completed', type: 'withdrawal' },
    { date: '2023-07-04', method: 'Completed Offer', amount: '$200', status: 'Completed', type: 'Deposit' },
  ];

  const calculateFee = (amount) => {
    return amount ? 1 : 0; // Assuming a flat fee of $1 for simplicity
  };

  const fee = calculateFee(selectedOption.replace('$', ''));
  const total = selectedOption ? `$${parseInt(selectedOption.replace('$', '')) + fee}` : '';

  return (
    <div className={styles.container}>
      <Typography variant="h4" className={styles.title}>Withdrawals</Typography>
      <Typography variant="h6" className={styles.balance}>Balance: ${balance}</Typography>
      <Typography variant="h6" className={styles.pendingBalance}>Pending Balance: ${pendingBalance}</Typography>
      <Grid container spacing={3} className={styles.section}>
        <Grid item xs={12}>
          <Typography variant="h5">Withdraw with Cash</Typography>
          <div className={styles.options}>
            {cashOptions.map(option => (
              <Button key={option} variant="contained" color="primary" onClick={() => handleOpenModal(option)}>
                {option}
              </Button>
            ))}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Withdraw with Gift Cards</Typography>
          <div className={styles.options}>
            {giftCardOptions.map(option => (
              <Button key={option} variant="contained" color="secondary" onClick={() => handleOpenModal(option)}>
                {option}
              </Button>
            ))}
          </div>
        </Grid>
      </Grid>
      <Typography variant="h5" className={styles.historyTitle}>Transaction History</Typography>
      <div className={styles.history}>
        <div className={styles.historyHeaders}>
          <Typography variant="body2" className={styles.historyHeader}>Date</Typography>
          <Typography variant="body2" className={styles.historyHeader}>Method</Typography>
          <Typography variant="body2" className={styles.historyHeader}>Amount</Typography>
          <Typography variant="body2" className={styles.historyHeader}>Status</Typography>
        </div>
        {transactionHistory.map((transaction, index) => (
          <div key={index} className={`${styles.historyItem} ${transaction.type === 'withdrawal' ? styles.withdrawal : styles.deposit}`}>
            <Typography variant="body2" className={styles.historyText}>{transaction.date}</Typography>
            <Typography variant="body2" className={styles.historyText}>{transaction.method}</Typography>
            <Typography variant="body2" className={styles.historyText}>{transaction.amount}</Typography>
            <Typography variant="body2" className={styles.historyText}>{transaction.status}</Typography>
          </div>
        ))}
      </div>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="withdraw-modal-title"
        aria-describedby="withdraw-modal-description"
      >
        <Box className={styles.modal}>
          <Typography id="withdraw-modal-title" variant="h6">
            {`Withdraw with ${withdrawMethod}`}
          </Typography>
          <Grid container spacing={3} className={styles.selectionContainer}>
            {amounts[withdrawMethod]?.map(amount => (
              <Grid item xs={6} key={amount}>
                <SelectionCard
                  amount={amount}
                  logo={`/images/${withdrawMethod.toLowerCase()}.png`}
                  onSelect={handleSelectOption}
                  selected={selectedOption === amount}
                />
              </Grid>
            ))}
          </Grid>
          <Button variant="contained" color="primary" onClick={handleSubmit} className={styles.submitButton}>
            Submit
          </Button>
          <Typography variant="body2" className={styles.fee}>Fee: ${fee}</Typography>
          <Typography variant="body2" className={styles.price}>{`Total: ${total}`}</Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Withdrawals;
